export const BUILD_SCOPES = [
  "Boilers",
  "Electrical System",
  "Emergency Backup Power Systems",
  "Mechanical",
  "Plumbing",
  "Sprinkler",
  "Solar Energy",
  "Standpipe",
  "New Building",
  "Builders Pavement Plan (BPP)",
  "Alteration: Manufacturing Buildings",
  "Alteration: Residential and Community Facilities",
  "Alteration: Multiple Dwelling Unit Buildings",
  "Alteration: Commercial, Mixed Use Buildings",
  "Place of Assembly Certificate of Operation",
  "Basement/Cellar Apartment",
  "Alteration: Commercial Office Building",
  "Alteration: 1 & 2 Family Building",
  "Alteration:  1 & 2 Family Building Mixed Use",
  "Alteration - Educational/institutional/Community Facilities",
  "Lot Subdivision and Merging",
  "Alteration: Hotels",
  "Alteration: Parking Lots and Parking Structures",
  "Temporary Tents and Other Structures",
  "Alteration: Loft Law Interim Multiple Dwellings (IMD)",
];
