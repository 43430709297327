import React, { useCallback } from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation, matchPath, generatePath } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { projectsAtom } from "../../atom/projectsAtom";
import { useCurrenPlanQaData } from "../../atom/planQaAtom";
import { LinearLoader } from "../other/LinearLoader";
import { usePlanReportsList } from "../../atom/planReportListAtom";
import { ROUTER_PATHS } from "./routerPaths";
import { useCurrentPlanReportData } from "../../atom/currentPlanReportAtom";
import { iconSvg } from "../../util/LogoSvg";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";

const IGNORE_STRINGS = ["pl", "qa", "report"];

const TEXT_MAP: { [key: string]: string } = {
  project: "Projects",
  question: "Q&A",
  [ROUTER_PATHS.ROOT]: "Projects",
  plans: "Plans",
  setup: "Setup",
};

const getFragmentsIdsLocations = (url: string) => {
  if (matchPath({ path: ROUTER_PATHS.ROOT }, url)) {
    return [false];
  }

  const path = Object.values(ROUTER_PATHS).find((path) =>
    matchPath({ path }, url),
  );

  if (!path) {
    return [];
  }

  return path.split("/").map((f) => f.startsWith(":"));
};

export const Breadcrumbs = () => {
  const location = useLocation();
  const projectStore = useRecoilValue(projectsAtom);
  const { activePlan } = useActivePlan();
  const planQaListData = usePlanReportsList();
  const planReportData = useCurrentPlanReportData();
  const currentPlanQa = useCurrenPlanQaData();
  const { plans } = useRecoilValue(currentProjectsAtom);
  const getUrlForPlan = (someId: string, isId: boolean) => {
    if (someId === "project") {
      return generatePath(ROUTER_PATHS.ROOT);
    }

    if (!isId) {
      return location.pathname;
    }

    const project = projectStore.list.find(
      (project) => project.projectId === someId,
    );

    if (project) {
      return generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
        projectId: project.projectId,
      });
    }

    if (!activePlan) {
      return location.pathname;
    }

    if (activePlan.id === someId) {
      return generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
        projectId: activePlan.projectId,
      });
    }

    if (planReportData.nullableData()?.reportId === someId) {
      return generatePath(ROUTER_PATHS.PLAN_REPORT, {
        projectId: activePlan.projectId,
        planId: activePlan.id,
        reportId: someId,
      });
    }

    return location.pathname;
  };

  const getSomeIdData = useCallback(
    (someId: string) => {
      const project = projectStore.list.find(
        (project) => project.projectId === someId,
      );
      if (project) {
        return <span>{project.address.formatted}</span>;
      }

      if (activePlan && activePlan.id === someId) {
        return <span>{activePlan.title}</span>;
      }

      const otherPlan = plans.find((p) => p.id === someId);
      if (otherPlan) {
        return <span>{otherPlan.title}</span>;
      }

      if (currentPlanQa.hasData() && currentPlanQa.data().planQaId === someId) {
        return <span>{currentPlanQa.data().title}</span>;
      }

      const planReport = planQaListData.isDone()
        ? planQaListData.data().find((a) => a.reportId === someId)
        : null;
      if (planReport) {
        return <span>{planReport.title}</span>;
      }

      return <LinearLoader />;
    },
    [projectStore.list, currentPlanQa, planQaListData, activePlan, plans],
  );

  const fragments = location.pathname.split("/");
  // The pathname starts with an empty string.
  fragments.shift();

  return (
    <Breadcrumb style={{ margin: "4 0 8px 0" }} className="breadcrumbs">
      <Breadcrumb.Item key="logo">{iconSvg}</Breadcrumb.Item>
      {getFragmentsIdsLocations(location.pathname).map((isId, index) => {
        const fragment = fragments[index];
        if (IGNORE_STRINGS.includes(fragment)) {
          return null;
        }

        let text = <span>{TEXT_MAP[fragment] ?? fragment}</span>;
        if (isId) {
          text = getSomeIdData(fragment);
        }

        return (
          <Breadcrumb.Item key={index}>
            <Link to={getUrlForPlan(fragment, isId)} className="breadcrumb">
              {text}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
