import React, { useMemo, useRef, useState } from "react";
import { niceDate } from "../../../util/dateUtil";
import { MarkdownCitation } from "./MarkdownCitation";
import {
  CityName,
  PlanQuestion,
  SourceItem,
} from "../../../model/blueprintServer";
import { BuildingCodeCard } from "./BuildingCodeCard";
import "./qaAnswer.scss";
import { Button, ButtonColor, ButtonSize } from "../../theme/Button";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

interface QAAnswerProps extends PlanQuestion {
  cityName: CityName;
  codeYear: number;
}

export const QAAnswer = ({
  created,
  question,
  answer,
  cityName,
  codeYear,
}: QAAnswerProps) => {
  const [showContent, setShowContent] = useState(false);

  const details = useMemo(() => {
    if (!showContent) {
      return null;
    }

    return (
      <div>
        <div className="sources flex">
          {answer.relevantSections.map((source, index) => (
            <Source
              source={source}
              key={index}
              city={cityName}
              year={codeYear}
            />
          ))}
        </div>
        <div>
          {answer.detailedAnalysis.map((details, index) => (
            <div key={index}>
              <h4 className="consideration">
                <MarkdownCitation markdown={details.requirement} />
              </h4>
              <div className="analysis">
                <MarkdownCitation markdown={details.provideddata} />
              </div>
              <div className="analysis">
                <MarkdownCitation markdown={details.analysis} />
              </div>
              <div className="analysis">
                <MarkdownCitation markdown={details.evaluation} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [showContent, answer, cityName, codeYear]);

  return (
    <div className={classNames("qa-answer", { "is-open": showContent })}>
      <div className="header flex">
        <span>Code year: {codeYear}</span>
        <span>{niceDate(created)}</span>
      </div>
      <div className="question">{question}</div>
      <div className="answer">
        <div>
          <MarkdownCitation markdown={answer.answer} />
        </div>
      </div>
      <div>
        <Button
          size={ButtonSize.SMALL}
          color={ButtonColor.BRAND}
          onClick={() => setShowContent((old) => !old)}
          classes="expend"
        >
          {showContent ? "Show less" : "Show more"}
        </Button>
      </div>
      {details}
    </div>
  );
};

interface SourceProps {
  source: SourceItem;
  city: CityName;
  year: number;
}

const Source = ({ source, city, year }: SourceProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className="source" ref={ref} data-tooltip-id={source.section}>
      <div className="source-title">{source.sectionTitle}</div>
      <div>§{source.section}</div>
      <Tooltip id={source.section} place="bottom-start" clickable>
        <BuildingCodeCard source={source} city={city} codeYear={year} />
      </Tooltip>
    </div>
  );
};
