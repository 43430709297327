import { useEffect, useState } from "react";
import { useResetRecoilState } from "recoil";
import { usePlanQaListData } from "../../../../atom/planQaAtom";
import { planQaApi } from "../../../../api/PlanQaApi";
import { questionsAtom } from "../../../../atom/questionAtom";
import { useActivePlan } from "../../hook/useActivePlan";

export const useLoadPlanQaList = () => {
  const resetQuestions = useResetRecoilState(questionsAtom);
  const planQaList = usePlanQaListData();
  const { activePlan } = useActivePlan();
  const [currentActivePlan, setCurrentActivePlan] = useState("");

  useEffect(() => {
    if (!activePlan) {
      return;
    }

    if (currentActivePlan === activePlan?.id || planQaList.isLoading()) {
      return;
    }

    setCurrentActivePlan(activePlan.id);
    resetQuestions();
    planQaList.startLoading();
    planQaApi.getAll(activePlan.id).then((data) => {
      planQaList.update(data);
      planQaList.endLoading();
    });
  }, [
    resetQuestions,
    planQaList,
    activePlan,
    currentActivePlan,
    setCurrentActivePlan,
  ]);
};
