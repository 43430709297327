import {
  planQaListAtom,
  useCurrenPlanQaData,
  usePlanQaListData,
} from "../../../atom/planQaAtom";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useUpdateCurrentPlanQa = () => {
  const { planQaId } = useParams();
  const inqueryList = useRecoilValue(planQaListAtom);
  const planQaList = usePlanQaListData();
  const currenPlanQaData = useCurrenPlanQaData();

  useEffect(() => {
    if (
      !planQaId ||
      planQaList.isNotDone() ||
      planQaId === currenPlanQaData.nullableData()?.planQaId
    ) {
      return;
    }

    const planQa =
      planQaList.data().find((inquery) => inquery.planQaId === planQaId) ??
      null;
    currenPlanQaData.update(planQa);
    currenPlanQaData.endLoading();
  }, [inqueryList, currenPlanQaData, planQaId, planQaList]);
};
