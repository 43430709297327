import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonColor } from "../../theme/Button";
import { FaQuestion } from "react-icons/fa";
import TextareaAutosize from "react-textarea-autosize";
import "./questionBox.scss";
import { Dropdown } from "../../other/dropdown/Dropdown";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { getSupportedYears } from "../project/ProjectDetails";

interface QuestionBoxProps {
  handleSubmit: (
    question: string,
    codeYear: number,
    onFinish: () => void,
  ) => void;
  planId: string;
  projectId: String;
}

export const QuestionBox = ({ handleSubmit }: QuestionBoxProps) => {
  const [question, setQuestion] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { model: project } = useRecoilValue(currentProjectsAtom);
  const [codeYear, setCodeYear] = useState(0);

  useEffect(() => {
    if (!codeYear && project) {
      setCodeYear(project.jurisdiction.codeYear);
    }
  }, [project, codeYear, setCodeYear]);

  useEffect(() => {
    textareaRef?.current?.focus();
  }, [textareaRef]);

  const handleChange = (e: any) => {
    setQuestion(e.target.value);
  };

  if (!project) {
    return null;
  }

  return (
    <div className="question-box">
      <div className="label">Ask a question</div>
      <TextareaAutosize
        onChange={handleChange}
        maxLength={10000}
        minRows={4}
        value={question}
        ref={textareaRef}
        disabled={loading}
        placeholder="Is the egress..."
      />
      <div className="flex buttons">
        <Dropdown
          value={codeYear}
          values={getSupportedYears(project.jurisdiction.cityName)}
          onChange={(val) => setCodeYear(val)}
          disabled={loading}
        />
        <Button
          color={ButtonColor.BRAND}
          disabled={question.length < 10 || loading}
          IconLeft={FaQuestion}
          onClick={() => {
            if (question.length < 10) {
              return;
            }
            setLoading(true);
            handleSubmit(question, codeYear, () => {
              setQuestion("");
              setLoading(false);
            });
          }}
        >
          Ask
        </Button>
      </div>
    </div>
  );
};
