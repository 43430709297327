import { PlanQA } from "../model/blueprintServer";
import { createDataAtom, useData } from "./AtomDataClass";

export const currenPlanQaAtom = createDataAtom<PlanQA>("currentPlanQa");

export const useCurrenPlanQaData = () => useData<PlanQA>(currenPlanQaAtom);

export const planQaListAtom = createDataAtom<PlanQA[]>("planQaList");

export const usePlanQaListData = () => useData<PlanQA[]>(planQaListAtom);
