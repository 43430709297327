import { FinalAssessment } from "../../model/blueprintServer";

export const getReportResultClass = (result: FinalAssessment) => {
  return getReportResultClassForStr(result.toString());
};
export const getReportResultClassForStr = (result: string) => {
  switch (result) {
    case FinalAssessment.DEEPER_REVIEW:
      return "report-deeper-review";
    case FinalAssessment.NO_ENOUGH_DATA:
      return "report-not-enough-data";
    case FinalAssessment.COMPLIANCE:
      return "report-compliance";
    case FinalAssessment.NON_COMPLIANCE:
      return "report-non-compliance";
    case FinalAssessment.ON_SITE_VERIFICATION:
      return "report-on-site-verification";
    case FinalAssessment.NOT_APPLICABLE:
      return "report-not-applicable";
  }
};

export const getReportResultText = (result: string) => {
  switch (result) {
    case FinalAssessment.DEEPER_REVIEW:
      return "Deeper review";
    case FinalAssessment.NO_ENOUGH_DATA:
      return "Not enough data";
    case FinalAssessment.COMPLIANCE:
      return "Compliance";
    case FinalAssessment.NON_COMPLIANCE:
      return "Non compliance";
    case FinalAssessment.ON_SITE_VERIFICATION:
      return "One site verification";
    case FinalAssessment.NOT_APPLICABLE:
      return "Not applicable";
  }
};
