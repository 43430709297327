import React, { useMemo, useState } from "react";
import { RightHandSideFoProps } from "./SideBySideView";
import { PlanQuestion } from "../../model/blueprintServer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../atom/currentProjectAtom";
import { QAAnswer } from "../projects/questions/QAAnswer";
import { questionApi } from "../../api/questionApi";
import { useCurrenPlanQaData } from "../../atom/planQaAtom";
import { useFetchBuildingCode } from "../projects/hook/useFetchBuildingCode";
import { questionsAtom } from "../../atom/questionAtom";
import "./rightHandSideForQa.scss";
import { QuestionBox } from "../projects/questions/QuestionBox";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { LinearLoader } from "../other/LinearLoader";

const extractCodeSections = (data: PlanQuestion[]) =>
  data.reduce(
    (prev, current) => [
      ...prev,
      ...current.answer.relevantSections.map((s) => s.section),
    ],
    [] as string[],
  );

interface RightHandSideForPlanQaProps
  extends RightHandSideFoProps<PlanQuestion> {}

export const RightHandSideForPlanQa = ({
  items,
}: RightHandSideForPlanQaProps) => {
  const { model: project } = useRecoilValue(currentProjectsAtom);
  const currentPlanQa = useCurrenPlanQaData();
  const [localLoading, setLocalLoading] = useState(false);
  const fetchCode = useFetchBuildingCode();
  const setQuestions = useSetRecoilState(questionsAtom);
  const { activePlan } = useActivePlan();

  const handleQuestion = (
    question: string,
    codeYear: number,
    onSubmit: () => void,
  ) => {
    if (!currentPlanQa.data || !project) {
      return;
    }

    setLocalLoading(true);
    questionApi
      .ask(currentPlanQa.data().planQaId, question, {
        ...project.jurisdiction,
        codeYear,
      })
      .then((data) => {
        setQuestions((old) => ({
          ...old,
          data: [...old.data, data],
        }));
        fetchCode(
          project.jurisdiction.cityName,
          project.jurisdiction.codeYear,
          extractCodeSections([data]),
        );
      })
      .finally(() => {
        setLocalLoading(false);
        onSubmit();
      });
  };

  const answers = useMemo(() => {
    if (!project) {
      return null;
    }

    return [...items]
      .reverse()
      .map((item) => (
        <QAAnswer
          {...item}
          codeYear={item.jurisdiction.codeYear}
          cityName={item.jurisdiction.cityName}
          key={item.id}
        />
      ));
  }, [items, project]);

  if (!activePlan || !project) {
    return null;
  }

  return (
    <div className="right-hand-size-for-qa flex">
      <QuestionBox
        handleSubmit={handleQuestion}
        planId={activePlan?.id}
        projectId={project.projectId}
      />
      {localLoading && (
        <div className="loader">
          <LinearLoader />
        </div>
      )}
      <div className="results flex">{answers}</div>
    </div>
  );
};
