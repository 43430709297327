import React from "react";
import "./privacyAndTerms.scss";

export const Privacy = () => (
  <div className="privacy-and-terms">
    <h2>Privacy Policy for Blue Plan AI</h2>
    <b>Effective date: March 26th, 2024</b>
    <p>
      Welcome to Blue Plan AI, the AI-driven platform dedicated to helping you
      ace your job interviews through personalized mock interviews and skill
      practices. Your privacy is of paramount importance to us, and this Privacy
      Policy outlines how we collect, use, store, and protect your personal
      information and data.
    </p>
    <p>
      Information We Collect Personal Information: Any information you provide
      when creating your account Interview Data: Responses, voice recordings,
      video recordings, and feedback from your mock interview sessions. Usage
      Data: Information on how you interact with our services, such as time
      spent on the platform, pages visited, and features used. Technical Data:
      IP address, browser type, device information, and other technical details
      to help improve your experience and our platform's security. How We Use
      Your Information To provide, personalize, and improve our services. To
      communicate with you about updates, support, and promotional offers,
      unless you opt-out. To analyze how our services are used, helping us to
      improve and develop new features. For security purposes, to protect our
      platform and users from fraud, abuse, and unauthorized access. Data
      Sharing and Disclosure With Service Providers: We share information with
      third-party service providers to perform services on our behalf, such as
      hosting, analytics, and customer support, under strict confidentiality
      agreements. For Legal Reasons: We may disclose your information if
      required by law or if we believe in good faith that such action is
      necessary to comply with legal processes, to protect our rights or the
      safety of our users, or to investigate fraud. Data Security We implement
      industry-standard security measures to protect your personal information
      from unauthorized access, alteration, or destruction. However, no method
      of transmission over the Internet or method of electronic storage is 100%
      secure.
    </p>
    <p>
      Your Rights Access and Correction: You have the right to access and update
      your personal information. Deletion: You may request the deletion of your
      account and associated data. Opt-Out: You can opt-out of receiving
      promotional communications from us. Children's Privacy Our platform is not
      intended for individuals under the age of 18. We do not knowingly collect
      personal information from children without parental consent.
    </p>
    <p>
      Changes to This Privacy Policy We may update our Privacy Policy from time
      to time. We will notify you of any changes by posting the new Privacy
      Policy on this page and updating the "Effective Date" at the top.
    </p>
    <p>
      Contact Us If you have any questions about this Privacy Policy, please
      contact us at{" "}
      <a href="mailto:hello@offerletterai.com">hello@offerletterai.com</a>,
    </p>
  </div>
);
