import { BaseApi } from "./BaseApi";
import { SignedUrlResponse } from "../model/blueprintServer";

class SignedUrlApi extends BaseApi {
  async getSignedUrl(): Promise<SignedUrlResponse> {
    return this.get("signed-url");
  }

  async uploadFile(file: File, signedUrl: string) {
    await this.putFile(signedUrl, file);
  }
}

export const signedUrlApi = new SignedUrlApi("plan-qa");
