import React from "react";
import "./emptyContainer.scss";

interface EmptyContainerProps {
  children: React.ReactNode;
}

export const EmptyContainer = ({ children }: EmptyContainerProps) => (
  <div className="empty-container">{children}</div>
);
