import { useResetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { planQaListAtom } from "../../../atom/planQaAtom";
import { useActivePlan } from "./useActivePlan";

export const useLoadCurrentPlan = () => {
  const { activePlan } = useActivePlan();
  const resetPlanQaList = useResetRecoilState(planQaListAtom);
  const [currentPlanId, setCurrentPlanId] = useState("");

  useEffect(() => {
    const planId = activePlan?.id;
    if (!planId || currentPlanId === planId) {
      return;
    }

    setCurrentPlanId(planId);
    resetPlanQaList();
  }, [resetPlanQaList, activePlan?.id, currentPlanId, setCurrentPlanId]);
};
