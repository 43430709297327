import { BuildingCodeRecord, CityName } from "../model/blueprintServer";

export const codeSymbol = (buildingCode: BuildingCodeRecord) => {
  if (
    buildingCode.city === CityName.NEW_YORK_CITY &&
    buildingCode.year === 1968
  ) {
    return "§";
  }
};
