import { Tooltip } from "react-tooltip";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "../../../atom/serverAuthFinishedAtom";
import { UserAvatar } from "../UserAvatar";
import React from "react";
import "./useMenu.scss";
import { CiLogout } from "react-icons/ci";
import { useAuth } from "../../../hooks/useAuth";

export const USER_MENU_TOOLTIP_ID = "userMenu";

export const UserMenu = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const { logout } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    logout();
  };

  return (
    <Tooltip
      id={USER_MENU_TOOLTIP_ID}
      openOnClick={true}
      place="top-start"
      clickable={true}
    >
      <div className="user-menu">
        <div className="flex user-details">
          <UserAvatar />
          {appLevelUser?.name}
        </div>
        <div className="menu">
          <div className="menu-item" onClick={handleLogout}>
            <CiLogout /> Logout
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
