import React, { useEffect, useState } from "react";
import {
  PlanQuestion,
  SectionComplianceReport,
} from "../../../model/blueprintServer";
import { SideBySideView } from "../../sideBySide/SideBySideView";
import { useActivePlan } from "../hook/useActivePlan";
import { assertDefined } from "../../../util/assertions";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { questionsAtom } from "../../../atom/questionAtom";
import { DataState } from "../../../atom/projectsAtom";
import { questionApi } from "../../../api/questionApi";
import { useFetchBuildingCode } from "../hook/useFetchBuildingCode";
import { useCurrenPlanQaData } from "../../../atom/planQaAtom";
import { RightHandSideForPlanQa } from "../../sideBySide/RightHandSideForPlanQa";
import { appMenuStateAtom } from "../../../atom/MenuStateAtom";

export const PlanQaPage = () => {
  const [questions, setQuestions] = useRecoilState(questionsAtom);
  const currentProject = useRecoilValue(currentProjectsAtom);
  const fetchCode = useFetchBuildingCode();
  const currentPlanQa = useCurrenPlanQaData();
  const resetQuestions = useResetRecoilState(questionsAtom);

  const setAppMenuState = useSetRecoilState(appMenuStateAtom);
  const [collapseAppSidebar, setCollapseAppSidebar] = useState(false);

  useEffect(() => {
    if (!collapseAppSidebar) {
      setAppMenuState((old) => ({ ...old, collapse: true }));
      setCollapseAppSidebar(true);
    }
  }, [collapseAppSidebar, setAppMenuState, setCollapseAppSidebar]);

  useEffect(() => {
    if (currentPlanQa.isNotDone() || !currentProject.model) {
      return;
    }

    const project = assertDefined(currentProject.model);
    if (questions.state === DataState.NOT_LOADED) {
      setQuestions((old) => ({ ...old, state: DataState.LOADING }));
      questionApi.getAll(currentPlanQa.data().planQaId).then((data) => {
        setQuestions((old) => ({ ...old, state: DataState.LOADED, data }));
        const codes = data.reduce(
          (prev, current) => [
            ...prev,
            ...current.answer.relevantSections.map((s) => s.section),
          ],
          [] as string[],
        );
        fetchCode(
          project.jurisdiction.cityName,
          project.jurisdiction.codeYear,
          codes,
        );
      });
    }
  }, [
    questions,
    setQuestions,
    resetQuestions,
    fetchCode,
    currentProject,
    currentPlanQa,
  ]);

  if (!currentProject.model) {
    return null;
  }

  return (
    <div>
      <SideBySideView<PlanQuestion>
        items={questions.data}
        cityName={currentProject.model.jurisdiction.cityName}
        RightHandSide={RightHandSideForPlanQa}
        title="Q&A"
      />
    </div>
  );
};
