import { DataState } from "../../../atom/projectsAtom";
import { signedUrlApi } from "../../../api/signedUrlApi";
import { planApi } from "../../../api/planApi";
import { useRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";

export const useUploadPlan = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);

  return async (file: File) => {
    if (!currentProject.model?.projectId) {
      return;
    }

    setCurrentProject((old) => ({ ...old, plansState: DataState.LOADING }));
    const projectId = currentProject.model.projectId;
    const signedUrlResponse = await signedUrlApi.getSignedUrl();
    await signedUrlApi.uploadFile(file, signedUrlResponse.signedUrl);
    const plan = await planApi.addPlan(projectId, signedUrlResponse.id);
    setCurrentProject((old) => ({
      ...old,
      plans: [...old.plans, plan],
      plansState: DataState.LOADED,
    }));
    setCurrentProject((old) => {
      if (!old.model) {
        return old;
      }
      return { ...old, model: { ...old.model, activePlanId: plan.id } };
    });
  };
};
