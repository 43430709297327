import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { buildingCodeAtom } from "../../../atom/buildingCodeAtom";
import "./buildingCodeCard.scss";
import { usePositionRelativeToParent } from "../../../hooks/usePositionRelativeToParent";
import { CityName, SourceItem } from "../../../model/blueprintServer";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import parse from "html-react-parser";
import { marked } from "marked";
import { useGetBuildingCode } from "../hook/useGetBuildingCode";

interface BuildingCodeCardProps {
  source: SourceItem;
  city: CityName;
  codeYear: number;
}

export const BuildingCodeCard = ({
  source,
  city,
  codeYear,
}: BuildingCodeCardProps) => {
  const getCodeYear = useGetBuildingCode();
  const buildingCode = getCodeYear(city, codeYear, source.section);

  const details = useMemo(() => {
    if (!buildingCode) {
      return null;
    }

    return (
      <div>
        <div className="title">{buildingCode.title}</div>
        <div className="section">section {buildingCode.fullSectionPath}</div>
        <div>{parse(marked.parse(buildingCode.text) as string)}</div>
      </div>
    );
  }, [buildingCode]);

  return (
    <div className="building-code-card">
      <div className="inner">
        {buildingCode ? details : <MultiLinearLoader />}
      </div>
    </div>
  );
};
