import { useCallback } from "react";
import { planQaApi } from "../../../../api/PlanQaApi";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../../app/routerPaths";
import { useRecoilValue } from "recoil";
import {
  useCurrenPlanQaData,
  usePlanQaListData,
} from "../../../../atom/planQaAtom";
import { currentProjectsAtom } from "../../../../atom/currentProjectAtom";
import { useActivePlan } from "../../hook/useActivePlan";

export const useCreatePlanQa = () => {
  const { activePlan } = useActivePlan();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const navigate = useNavigate();
  const currentPlanQa = useCurrenPlanQaData();
  const planQaListData = usePlanQaListData();

  return useCallback(() => {
    if (!activePlan) {
      return;
    }

    currentPlanQa.startLoading();
    planQaApi.create(activePlan.id).then((planQa) => {
      currentPlanQa.update(planQa);
      currentPlanQa.endLoading();
      const path = generatePath(ROUTER_PATHS.PLAN_QA, {
        projectId: currentProject.model?.projectId,
        planId: activePlan.id,
        planQaId: planQa.planQaId,
      });
      planQaListData.updateFn((old) => ({
        ...old,
        model: [...(old.model ?? []), planQa],
      }));
      navigate("/" + path);
    });
  }, [
    activePlan,
    navigate,
    currentProject.model?.projectId,
    currentPlanQa,
    planQaListData,
  ]);
};
