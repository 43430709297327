import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { gEvent } from "../../util/gtag";
import "./appHeader.scss";
import { PageBehindAuth } from "./PageBehindAuth";
import { useAppWithAuth } from "../../hooks/useAppWithAuth";

export const Page = () => {
  const location = useLocation();
  useAppWithAuth();

  useEffect(() => {
    document
      .getElementById("outlet")
      ?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div id="page" className="page">
      <PageBehindAuth />
    </div>
  );
};
