import React, { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { useNavigate, useParams } from "react-router-dom";
import { PlanAnnotator } from "./PlanAnnotator";
import "./planQaAnnotator.scss";

export const PlanQaAnnotator = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const { planId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!planId) {
      navigate("/projects");
    }
  }, [planId, navigate]);

  const plan = useMemo(() => {
    const plan = currentProject.plans.find((p) => p.id === planId);
    if (!plan) {
      navigate("/projects");
      return null;
    }

    return plan;
  }, [currentProject.plans, navigate, planId]);

  if (!plan) {
    return null;
  }

  return (
    <div className="project-qa-annotator">
      <PlanAnnotator plan={plan} />
    </div>
  );
};
