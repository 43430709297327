import { PagesIndex } from "../../planReport/PagesIndex";
import { LeftHandSide } from "../../sideBySide/LeftHandSide";
import React, { useState } from "react";
import { useActivePlan } from "../hook/useActivePlan";
import "./planViewer.scss";

export const PlanViewer = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const { activePlan } = useActivePlan();

  const setPageIndex = (pageIndex: number) => {
    if (!activePlan) {
      return;
    }

    if (
      pageIndex > -1 &&
      pageIndex < activePlan.pages.length &&
      pageIndex !== currentPageIndex
    ) {
      setCurrentPageIndex(pageIndex);
      setIsLoadingImage(true);
    }
  };

  if (!activePlan) {
    return null;
  }

  return (
    <div className="plan-viewer flex">
      <PagesIndex
        setPageIndex={setPageIndex}
        currentPageIndex={currentPageIndex}
      />
      <LeftHandSide
        page={activePlan.pages[currentPageIndex]}
        handleFinishLoadImage={() => setIsLoadingImage(false)}
        isLoadingImage={isLoadingImage}
      />
    </div>
  );
};
