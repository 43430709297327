import React from "react";
import { Rect, XYPoint } from "../../model/blueprintServer";

type MoveEvent = React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent;

const getPosition = (e: MoveEvent) => {
  const obj = "touches" in e ? e.touches[0] : e;
  return { pageX: obj.pageX, pageY: obj.pageY };
};

export const useDrag = (onRect: (rect: Rect, isNewRect: boolean) => void) => {
  let start: XYPoint | null = null;
  let current: XYPoint | null = null;
  const handleMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();

    const handleMouseMove = (event: MouseEvent | TouchEvent) => {
      event.preventDefault();
      const { pageX: moveX, pageY: moveY } = getPosition(event);
      const x = moveX - rect.left;
      const y = moveY - rect.top - window.pageYOffset;

      if (x < 0 || y < 0) {
        return;
      }

      if (!start) {
        start = { x, y };
        return;
      }

      current = { x, y };
      onRect({ start, end: current }, false);
    };

    const handleMouseUp = () => {
      if (start && current) {
        onRect({ start, end: current }, true);
        start = null;
        current = null;
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);
  };

  return { handleMouseDown };
};
