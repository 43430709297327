import React, { useState } from "react";
import { BaseApi } from "../../api/BaseApi";
import { PlanModel } from "../../model/blueprintServer";
import { adminApi } from "../../api/adminApi";

interface AdminProcessPlanTempProps {
  planId: string;
}

export const AdminProcessPlanTemp = ({ planId }: AdminProcessPlanTempProps) => {
  const [planQaId, setPlanQaId] = useState("");
  const [callStatus, setCallState] = useState("");

  const markComplete = async () => {
    setCallState("Loading");
    try {
      await adminApi.markComplete(planId);
      setCallState("Success");
    } catch (e) {
      setCallState("Failed to mark complete");
    }
  };

  const copyAnnotations = async () => {
    setCallState("Loading");
    try {
      await adminApi.copyAnnotations(planId, planQaId);
      setCallState("Success");
    } catch (e) {
      setCallState("Failed to copy annotations");
    }
  };

  return (
    <div>
      <h3>Plan onboarding</h3>
      <div>{callStatus}</div>
      <button onClick={markComplete}>Mark complete</button>
      <div>
        <input
          placeholder="planQaId"
          onChange={(e) => setPlanQaId(e.target.value)}
        />
        <button onClick={copyAnnotations} disabled={!planQaId}>
          Copy annotations
        </button>
      </div>
    </div>
  );
};
