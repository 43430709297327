import { RefObject, useEffect } from "react";

export const useHideOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  setIsOpen: (val: boolean) => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsOpen]);
};
