import { BaseApi } from "./BaseApi";
import {
  BuildingCodeRecord,
  CityName,
  ReportPreset,
} from "../model/blueprintServer";

class BuildingCodeApi extends BaseApi {
  async getCodes(
    cityName: CityName,
    year: number,
    sections: string[],
  ): Promise<BuildingCodeRecord[]> {
    return this.get(
      `get?cityName=${cityName}&year=${year}&sections=${sections.join(",")}`,
    );
  }

  async getSectionsList(cityName: CityName, year: number): Promise<string[]> {
    return this.get(`sections?cityName=${cityName}&year=${year}`);
  }

  async getReportPreset(projectId: string): Promise<ReportPreset[]> {
    return this.get(`report-presets?projectId=${projectId}`);
  }
}

export const buildingCodeApi = new BuildingCodeApi("building-code");
