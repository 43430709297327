import React from "react";
import AutoComplete from "react-google-autocomplete";
import { useRecoilValue } from "recoil";
import { DataState, projectsAtom } from "../../atom/projectsAtom";
import { SpinnerLoader } from "./SpinnerLoader";

const options: google.maps.places.AutocompleteOptions = {
  types: ["geocode"],
  componentRestrictions: { country: "us" },
};

interface AddressFormProps {
  handleSubmit: (place: string) => void;
}

export const AddressForm = ({ handleSubmit }: AddressFormProps) => {
  const projectStore = useRecoilValue(projectsAtom);

  const handlePlace = (place: google.maps.places.PlaceResult | null) => {
    if (!place || !place.place_id) {
      throw new Error("Can't find place");
    }

    handleSubmit(place.place_id);
  };

  if (projectStore.state === DataState.LOADING) {
    return <SpinnerLoader />;
  }

  return (
    <AutoComplete
      apiKey={"AIzaSyD2Q4Vg3INk4Dz8Kp72eUlaBNV_cADNeQQ"}
      onPlaceSelected={handlePlace}
      options={options}
    />
  );
};

const findAddressComponent = (
  components: google.maps.GeocoderAddressComponent[],
  type: string,
) => {
  return components.find((c) => c.types.includes(type));
};

const getAddress = (place: google.maps.places.PlaceResult) => {
  const components = place.address_components;
  if (!components) {
    return null;
  }

  const number = findAddressComponent(components, "street_number");
  if (!number) {
    return null;
  }

  const street = findAddressComponent(components, "route");
  if (!number) {
    return null;
  }
};
