import React, { useCallback, useEffect, useMemo } from "react";
import "./projectDetails.scss";
import { useRecoilState, useResetRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { projectApi } from "../../../api/projectApi";
import { CityName, ProjectSize } from "../../../model/blueprintServer";
import { Dropdown } from "../../other/dropdown/Dropdown";
import { FaTrashCan } from "react-icons/fa6";
import { BUILD_SCOPES } from "./scopesUtil";
import TextareaAutosize from "react-textarea-autosize";
import { Input } from "../../other/Input";
import { getCityDisplayName } from "../../../util/addressUtil";
import { assertDefined } from "../../../util/assertions";
import { Box } from "../../theme/Box";
import { useParams } from "react-router-dom";

export const getSupportedYears = (cityName: CityName) => {
  if (cityName === CityName.NEW_YORK_CITY) {
    return [2022, 2008, 1968, 1938];
  }

  if (cityName === CityName.PHILADELPHIA) {
    return [2018];
  }

  return [];
};

export const ProjectDetails = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);
  const resetCurrentProject = useResetRecoilState(currentProjectsAtom);
  const { projectId } = useParams();

  useEffect(() => {
    if (!projectId) {
      resetCurrentProject();
    }
  }, [projectId, resetCurrentProject]);

  const updateProjectSize = useCallback(
    (projectSize: ProjectSize) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = { ...old, model: { ...old.model, projectSize } };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateProjectType = useCallback(
    (projectType: string) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = { ...old, model: { ...old.model, projectType } };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateCodeYear = useCallback(
    (codeYear: number) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = {
          ...old,
          model: {
            ...old.model,
            jurisdiction: {
              ...old.model.jurisdiction,
              codeYear: Number(codeYear),
            },
          },
        };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateAreaName = useCallback(
    (areaName: string) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = {
          ...old,
          model: {
            ...old.model,
            jurisdiction: { ...old.model.jurisdiction, areaName },
          },
        };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const addScope = useCallback(
    (scope: string) => {
      setCurrentProject((old) => {
        if (!old.model || old.model.scopes.includes(scope)) {
          return old;
        }

        const projectModel = {
          ...old,
          model: { ...old.model, scopes: [...old.model.scopes, scope] },
        };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const removeScope = useCallback(
    (index: number) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const scopes = [...old.model.scopes];
        scopes.splice(index, 1);
        const projectModel = { ...old, model: { ...old.model, scopes } };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const updateFreeformScope = useCallback(
    (freeformScope: string) => {
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }

        const projectModel = { ...old, model: { ...old.model, freeformScope } };
        projectApi.updateProjectDetails(projectModel.model);
        return projectModel;
      });
    },
    [setCurrentProject],
  );

  const project = assertDefined(currentProject.model);

  const details = useMemo(() => {
    return (
      <div className="details flex">
        <div className="project-item">
          <div className="label">
            <span>Type</span>
          </div>
          <Dropdown
            value={project.projectType}
            values={["Commercial", "Residential", "Mixed used", "School"]}
            onChange={(val) => updateProjectType(val)}
          />
        </div>
        <div className="project-item">
          <div className="label">
            <span>Size</span>
          </div>
          <Dropdown
            value={project.projectSize}
            values={[
              ProjectSize._1000,
              ProjectSize._5000,
              ProjectSize._10000,
              ProjectSize._20000,
              ProjectSize._50000,
              ProjectSize._100000,
            ]}
            valueFn={(p) => {
              if (!p) {
                return "";
              }
              const numString = p.slice(1, p.length);
              const formatter = new Intl.NumberFormat("en-US");
              const formattedNumber = formatter.format(Number(numString));
              return `${formattedNumber} sqft`;
            }}
            onChange={(val) => updateProjectSize(val)}
          />
        </div>
        <div className="project-item">
          <div className="label">
            <span>Code year</span>
          </div>
          <Dropdown
            value={project.jurisdiction.codeYear}
            values={getSupportedYears(project.jurisdiction.cityName)}
            onChange={(val) => updateCodeYear(val)}
          />
        </div>
        <div className="project-item">
          <div className="label">
            <span>Area</span>
          </div>
          <Input
            value={getCityDisplayName(project.address.city)}
            disabled={true}
            onChange={updateAreaName}
          />
        </div>
      </div>
    );
  }, [
    project,
    updateProjectType,
    updateProjectSize,
    updateAreaName,
    updateCodeYear,
  ]);

  const scope = useMemo(
    () => (
      <div className="scopes">
        <div className="active-scopes flex">
          <Dropdown
            value={"Select"}
            values={BUILD_SCOPES}
            onChange={addScope}
            searchFn={(item, query) =>
              item.toLowerCase().includes(query.toLowerCase())
            }
          />
          {project.scopes.map((scope, index) => (
            <div
              key={index}
              onClick={() => removeScope(index)}
              className="pill flex"
            >
              <span>{scope}</span>
              <div className="delete flex">
                <FaTrashCan />
              </div>
            </div>
          ))}
        </div>
        <div className="freeform-scope">
          <div className="label">Additional scope</div>
          <TextareaAutosize
            maxLength={300}
            defaultValue={project.freeformScope}
            onChange={(e) => updateFreeformScope(e.target.value)}
            minRows={3}
          />
          {project.freeformScope.length > 299 && (
            <div>Limited to 300 characters</div>
          )}
        </div>
      </div>
    ),
    [project, updateFreeformScope, removeScope, addScope],
  );

  return (
    <div className="project-details flex">
      <Box title="Setup" body={details} />
      {/*<Box title="Scope" body={scope} />*/}
    </div>
  );
};
