import { CityName } from "../../../model/blueprintServer";

const linksRegex = /\[(.*?)\]\((.*?)\)/g;

// This interface is matched by a python AI call model, we can't change that alone.
export interface Citation {
  type: string;
  section: string;
  year: number;
  cityName: CityName;
}

const getCitationHtml = (text: string, citation: string) =>
  `<span data-citation='${citation}'>${text}</span>`;

const replaceAt = (
  text: string,
  indexPosition: number,
  replaceLength: number,
  chr: string,
) => {
  if (indexPosition > text.length - 1) return text;
  let out =
    text.substring(0, indexPosition) +
    text.substring(indexPosition + replaceLength);
  out = out.slice(0, indexPosition) + chr + out.slice(indexPosition);
  return out;
};

export interface TextAndCitations {
  text: string;
  citations: Citation[];
}

export const detectSpecialLinks = (text: string): TextAndCitations => {
  const citations: Citation[] = [];
  let match;
  while ((match = linksRegex.exec(text)) != null) {
    try {
      const citation = JSON.parse(match[2]) as Citation;
      if (citation && citation?.type === "section") {
        const citationHtmlString = getCitationHtml(match[1], match[2]);
        const replaceLength = match[0].length;
        text = replaceAt(
          text,
          match.index || 0,
          replaceLength,
          citationHtmlString,
        );
        citations.push(citation);
      }
    } catch (e) {
      console.warn("Failed to parse potential link: " + match);
    }
  }

  return { text, citations };
};
