import React from "react";
import { Input } from "../../other/Input";
import { SectionComplianceFilter } from "../../sideBySide/RightHandSideForReport";
import { FaSearch } from "react-icons/fa";
import "./reportResultFilter.scss";
import {
  FinalAssessmentFilter,
  FinalAssessmentSelection,
} from "./FinalAssessmentFilter";

interface ReportResultFilterProps {
  filters: SectionComplianceFilter;
  setFilters: (filters: SectionComplianceFilter) => void;
}
export const ReportResultFilter = ({
  filters,
  setFilters,
}: ReportResultFilterProps) => {
  const updateInput = (input: string) => {
    const newFilters = { ...filters, input };
    setFilters(newFilters);
  };

  const updateCategories = (categories: FinalAssessmentSelection) => {
    setFilters({ ...filters, categories });
  };

  return (
    <div className="report-result-filter">
      <div className="title">Filters</div>
      <div className="flex">
        <Input
          value={filters.input}
          onChange={updateInput}
          placeholder="Search"
          IconLeft={FaSearch}
        />
        <FinalAssessmentFilter
          updateCategories={updateCategories}
          categories={filters.categories}
        />
      </div>
    </div>
  );
};
