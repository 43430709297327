export const ROUTER_PATHS = {
  HOMEPAGE: "https://blueplanai.com/",
  ROOT: "",
  PROJECT_SETTINGS: "project/:projectId",
  PROJECT_PLANS_LIST: "project/:projectId/plans",

  PLAN: "project/:projectId/pl/:planId",
  PLAN_VIEWER: "project/:projectId/plan",
  PLAN_SETTINGS: "project/:projectId/pl/:planId",
  PLAN_QA_ANNOTATION: "project/:projectId/pl/:planId/qa/:planQaId/annotation",
  PLAN_QA: "project/:projectId/pl/:planId/qa/:planQaId",

  PLAN_REPORT: "project/:projectId/pl/:planId/report/:reportId",

  ORGANIZATION: "organization",
  SUPPORT: "support",
};
