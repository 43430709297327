import React, { ReactNode } from "react";
import "./modal.scss";

interface ModalProps {
  handleBackdropClick: () => void;
  children: ReactNode;
}

export const Modal = ({ handleBackdropClick, children }: ModalProps) => {
  return (
    <div className="modal">
      <div className="backdrop" onClick={handleBackdropClick} />
      <div className="container">{children}</div>
    </div>
  );
};
