import { useCallback, useEffect, useState } from "react";
import { useCurrentPlanReportData } from "../../../atom/currentPlanReportAtom";
import { planReportApi } from "../../../api/planReportApi";

export const useFetchPlanReport = () => {
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(
    null,
  );
  const planReportData = useCurrentPlanReportData();

  const fetchPlanReport = useCallback(
    async (reportId: string, showLoader: boolean = true) => {
      if (showLoader) {
        planReportData.startLoading();
      }
      const planReport = await planReportApi.getReport(reportId);
      planReportData.update(planReport).endLoading();
      return planReport;
    },
    [planReportData],
  );

  return { fetchPlanReport };
};
