import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/theme.scss";
import "./styles/complianceReport.scss";
import "./styles/pill.scss";
import "./styles/sideMenuStyle.scss";
import "./styles/tableTheme.scss";
import "./styles/transitions.scss";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";
import "react-loading-skeleton/dist/skeleton.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
