import React from "react";

export const iconSvg = (
  <svg
    className="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <rect
      x="389.268"
      y="336.582"
      width="157.8"
      height="620.017"
      rx="50"
      transform="rotate(-45 389.268 336.582)"
    />
    <rect
      x="498.418"
      y="225"
      width="157.8"
      height="620.017"
      rx="50"
      transform="rotate(45 498.418 225)"
    />
  </svg>
);
