import React from "react";
import { Link } from "react-router-dom";
import "./errorPage.scss";

export const ErrorPage = () => {
  return (
    <div className="error-page">
      <h1>Something went wrong</h1>
      <div>
        If you think this is a mistake, please{" "}
        <Link to="https://blueplanai.com/contact">contact us</Link>.
      </div>
    </div>
  );
};
