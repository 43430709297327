import React from "react";
import { CityName } from "../../model/blueprintServer";
import "./sideBySide.scss";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { PlanViewer } from "../projects/planManagment/PlanViewer";

export interface RightHandSideFoProps<T> {
  items: T[];
}

interface PlanReportProps<T> {
  items: T[];
  cityName: CityName;
  RightHandSide: React.ComponentType<RightHandSideFoProps<T>>;
  title: React.ReactNode;
  additionalTitle?: React.ReactNode;
}

export const SideBySideView = <T,>({
  items,
  RightHandSide,
  title,
  additionalTitle,
}: PlanReportProps<T>) => {
  const { activePlan } = useActivePlan();

  if (!activePlan) {
    return null;
  }

  return (
    <div className="side-by-side-view flex">
      <h2 className="flex">
        <span>{title}</span>
        <div className="created">{additionalTitle}</div>
      </h2>
      <div className="flex container">
        <PlanViewer />
        <RightHandSide items={items as T[]} />
      </div>
    </div>
  );
};
