import { useRecoilState } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { PlanModel } from "../../../model/blueprintServer";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { isDataProcessing } from "../../../util/dataLoadingProcessing";
import { planApi } from "../../../api/planApi";
import { useParams } from "react-router-dom";

const POLL_TIME = 1000 * 60;

export const usePollPlans = () => {
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectsAtom);
  const ref = useRef<string>();
  const { projectId } = useParams();

  const shouldPoll = useMemo(
    () => currentProject.plans.some((plan) => isDataProcessing(plan.status)),
    [currentProject.plans],
  );

  const poll = useCallback(async () => {
    if (!currentProject.model || !ref.current || !shouldPoll) {
      return;
    }

    let plans: PlanModel[] = [];
    try {
      plans = await planApi.getPlans(currentProject.model.projectId);
    } catch (e) {
      console.error("Failed to poll plans");
      console.error(e);
      return;
    }

    setCurrentProject((old) => ({ ...old, plans }));
    setTimeout(() => poll(), POLL_TIME);
  }, [currentProject.model, setCurrentProject, shouldPoll, ref]);

  useEffect(() => {
    if (!projectId) {
      ref.current = "";
      return;
    }

    if (ref.current === projectId) {
      return;
    }

    ref.current = currentProject?.model?.projectId ?? "";
    poll();
  }, [poll, currentProject.model, ref, projectId]);
};
