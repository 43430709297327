import React, { ReactNode, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./uploadContainer.scss";
import { useUploadPlan } from "../projects/hook/useUploadPlan";

interface UploadBluePrintPdfProps {
  text: ReactNode;
  handlePostUpload?: () => void;
  setLoadingState?: (state: boolean) => void;
}

export const UploadContainer = ({
  text,
  handlePostUpload,
  setLoadingState = () => {},
}: UploadBluePrintPdfProps) => {
  const uploadPlan = useUploadPlan();
  const [active, setActive] = useState(false);
  const onDropAccepted = async (files: any[]) => {
    setActive(false);
    if (!files.length) {
      return;
    }

    setLoadingState(true);
    await uploadPlan(files[0]);
    setLoadingState(false);
    if (handlePostUpload) {
      handlePostUpload();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDragEnter: () => setActive(true),
    onDragLeave: () => setActive(false),
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const className = `dropzone${active ? " active" : ""}`;

  return (
    <section className="upload-container">
      <div {...getRootProps({ className: className })}>
        <input {...getInputProps()} />
        <p className="inner">{text}</p>
      </div>
    </section>
  );
};
