import React, { useCallback, useMemo } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { getCityId } from "../../../util/addressUtil";
import { useRecoilValue } from "recoil";
import { DataState, projectsAtom } from "../../../atom/projectsAtom";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { EmptyContainer } from "../../other/EmptyContainer";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { Button, ButtonColor, ButtonSize } from "../../theme/Button";
import { ProjectModel } from "../../../model/blueprintServer";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DebouncedInput } from "../../other/DebounceInput";
import { rankItem } from "@tanstack/match-sorter-utils";
import "./projects.scss";
import { serverAuthFinishedAtom } from "../../../atom/serverAuthFinishedAtom";
import { GoPlus } from "react-icons/go";
import { useModal } from "../../theme/useModal";
import { CreateProject } from "./CreateProject";
import { Table } from "../../theme/Table";

const columnHelper = createColumnHelper<ProjectModel>();

const columns = [
  columnHelper.accessor("address", {
    header: () => <span>Address</span>,
    cell: (info) => {
      return (
        <Link
          to={generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
            projectId: info.row.id,
          })}
        >
          {info.row.original.address.formatted}
        </Link>
      );
    },
  }),
  // @ts-ignore
  columnHelper.accessor("cityId", {
    header: () => <span>City Id</span>,
    cell: (info) => getCityId(info.row.original.address),
  }),
  columnHelper.accessor("created", {
    header: () => <span>Created</span>,
    cell: (info) => new Date(info.getValue()).toDateString(),
  }),
];

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

export const Projects = () => {
  const projectsStore = useRecoilValue(projectsAtom);
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const table = useReactTable({
    data: projectsStore.list,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      globalFilter,
    },
  });
  const { showModal } = useModal();

  const onRowClick = useCallback(
    (project: ProjectModel) =>
      navigate(
        "/" +
          generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
            projectId: project.projectId,
          }),
      ),
    [navigate],
  );

  const projectsItems = useMemo(
    () => (
      <Table<ProjectModel>
        table={table}
        onRowClick={onRowClick}
        classes="list"
      />
    ),
    [table, onRowClick],
  );

  const projectsList = (
    <div className="projects-list">
      <div className="actions flex">
        <div className="search flex">
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Search"
          />
        </div>
        <Button
          size={ButtonSize.SMALL}
          color={ButtonColor.BRAND}
          onClick={() => showModal(CreateProject)}
          IconLeft={GoPlus}
          classes="create-project"
        >
          Create
        </Button>
      </div>
      {projectsStore.state === DataState.LOADING && <MultiLinearLoader />}
      {projectsStore.state === DataState.LOADED && projectsStore.list.length ? (
        projectsItems
      ) : (
        <EmptyContainer>No projects</EmptyContainer>
      )}
    </div>
  );

  return (
    <div className="projects">
      <h3>Welcome, {appLevelUser?.name}</h3>
      <div className="welcome flex">
        <img className="logo" src="/logo.png" />
        <div>
          <h4>Get started with Blueplan</h4>
          <div>
            To set up your account, create the first <br /> project and your
            first plan to Blueplan AI.
          </div>
        </div>
      </div>
      {projectsList}
    </div>
  );
};
