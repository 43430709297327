import React from "react";
import "./loaders.scss";

export const LinearLoader = () => {
  return (
    <div className="linear-loader">
      <span className="inner"></span>
    </div>
  );
};
