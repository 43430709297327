import React from "react";
import { ProjectDetails } from "./ProjectDetails";
import "./current-plan-page.scss";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { AppLogoLoader } from "../../other/AppLogoLoader";
import { ActivePlan } from "../planManagment/ActivePlan";
import { PlanQaList } from "../planManagment/PlanQaList";
import { ReportsList } from "../planManagment/ReportsList";

export const CurrentPlanPage = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);

  if (!currentProject.model) {
    return <AppLogoLoader />;
  }

  return (
    <div className="flex current-plan-page">
      <div className="create-actions flex">
        <ActivePlan />
        <ProjectDetails />
      </div>
      <div className="create-actions flex">
        <PlanQaList />
        <ReportsList />
      </div>
    </div>
  );
};
