import React, { useMemo } from "react";
import { useActivePlan } from "../hook/useActivePlan";
import { Box } from "../../theme/Box";
import { useRecoilValue } from "recoil";
import { usePlanQaListData } from "../../../atom/planQaAtom";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { useCreatePlanQa } from "../planQa/hook/useCreatePlanQa";
import { Button, ButtonColor } from "../../theme/Button";
import "./planQaList.scss";
import { FaPlus } from "react-icons/fa";
import { niceDate } from "../../../util/dateUtil";

export const PlanQaList = () => {
  const { activePlan, isProcessingPlan } = useActivePlan();
  const planQaList = usePlanQaListData();
  const currentProject = useRecoilValue(currentProjectsAtom);
  const createPlanQa = useCreatePlanQa();
  const navigate = useNavigate();

  const list = useMemo(() => {
    if (!activePlan) {
      return <div>Upload your first plan</div>;
    }

    if (planQaList.isNotDone()) {
      return <MultiLinearLoader />;
    }

    if (isProcessingPlan) {
      return <div>Your plan is processing</div>;
    }

    if (planQaList.data().length === 0) {
      return <div>Create your first QA</div>;
    }

    const items = planQaList.data().map((planQa) => {
      return (
        <tr key={planQa.planQaId}>
          <td>{planQa.title}</td>
          <td>{niceDate(planQa.created)}</td>
          <td className="open-link">
            <Button
              onClick={() =>
                navigate(
                  "/" +
                    generatePath(ROUTER_PATHS.PLAN_QA, {
                      projectId: currentProject?.model?.projectId,
                      planId: activePlan.id,
                      planQaId: planQa.planQaId,
                    }),
                )
              }
            >
              Open
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    );
  }, [
    planQaList,
    currentProject?.model?.projectId,
    activePlan,
    isProcessingPlan,
    navigate,
  ]);

  return (
    <Box
      title="Q&A"
      classes="plan-qa-list"
      actionItem={
        <Button
          onClick={createPlanQa}
          color={ButtonColor.BRAND}
          disabled={!activePlan || isProcessingPlan}
          classes="add flex"
          IconLeft={FaPlus}
        >
          Create
        </Button>
      }
      body={list}
    />
  );
};
