import React, { useState } from "react";
import { ComponentData } from "../../model/blueprintServer";
import { ModalContentComponent } from "../theme/useModal";
import { adminApi } from "../../api/adminApi";
import "./adminUpdateComponentData.scss";

export interface AdminUpdateComponentDataData {
  planId: string;
  pageNumber: number;
  componentIndex: number;
  componentData: ComponentData;
}

const AdminUpdateComponentData = ({
  data,
  handleClose,
}: ModalContentComponent<AdminUpdateComponentDataData>) => {
  if (!data) {
    return null;
  }

  return <AdminUpdateComponentDataInner {...data} handleClose={handleClose} />;
};

interface AdminUpdateComponentDataInnerProps
  extends AdminUpdateComponentDataData {
  handleClose: () => void;
}

const AdminUpdateComponentDataInner = ({
  componentIndex,
  componentData,
  pageNumber,
  planId,
  handleClose,
}: AdminUpdateComponentDataInnerProps) => {
  const [title, setTitle] = useState(componentData.title);
  const [description, setDescription] = useState(componentData.description);
  const [extractedData, setExtractedData] = useState(
    componentData.extractedData,
  );
  const [categories, setCategories] = useState(
    componentData.categories.join(", "),
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const updatedComponentData: ComponentData = {
      title,
      description,
      extractedData,
      categories: categories.split(",").map((cat) => cat.trim()),
    };

    try {
      await adminApi.updateComponentText(
        planId,
        pageNumber,
        componentIndex,
        updatedComponentData,
      );
      setSuccess(true);
    } catch (err) {
      setError("Failed to update component data");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-update-component-data">
      <h2>Update Component Data</h2>

      <label>
        Title:
        <textarea value={title} onChange={(e) => setTitle(e.target.value)} />
      </label>

      <label>
        Description:
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </label>

      <label>
        Extracted Data:
        <textarea
          value={extractedData}
          onChange={(e) => setExtractedData(e.target.value)}
        />
      </label>

      <label>
        Categories (comma-separated):
        <textarea
          value={categories}
          onChange={(e) => setCategories(e.target.value)}
        />
      </label>

      <button onClick={handleUpdate} disabled={loading}>
        {loading ? "Updating..." : "Update Component"}
      </button>

      {success && (
        <p>
          Component updated successfully,{" "}
          <strong>Daniel refresh the page to see!</strong>
        </p>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
      <button onClick={handleClose}>Close</button>
    </div>
  );
};

export default AdminUpdateComponentData;
