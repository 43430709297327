import React from "react";
import { LinearLoader } from "./LinearLoader";

export const MultiLinearLoader = () => {
  return (
    <div className="multi-linear">
      <LinearLoader />
      <LinearLoader />
      <LinearLoader />
      <LinearLoader />
      <LinearLoader />
    </div>
  );
};
