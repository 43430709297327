import React, { useEffect, useMemo, useRef, useState } from "react";
import { BlobStorage, PlanQA } from "../../../model/blueprintServer";
import { useCanvas } from "../../canvas/useCanvas";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../../atom/pageImageSizeAtom";
import { formatImageUrl } from "../../../util/imageUtil";
import { CanvasMenu } from "../../canvas/CanvasMenu";

interface PlanCanvasProps {
  pageImageBlob: BlobStorage;
  inquery: PlanQA;
  pageNumber: number;
}

export const PlanCanvas = ({
  pageImageBlob,
  inquery,
  pageNumber,
}: PlanCanvasProps) => {
  const [selectedAnnotationKey, setSelectedAnnotationKey] = useState<
    string | null
  >(null);
  const { Canvas } = useCanvas(pageImageBlob, pageNumber, inquery, (key) =>
    setSelectedAnnotationKey(key),
  );
  const { adjustedContainerWidth, adjustedContainerHeight } =
    useRecoilValue(pageImageSizeAtom);
  const [float, setFloat] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const target = document.getElementById("canvas");
      if (!target) {
        return;
      }

      const rect = target.getBoundingClientRect();
      const isBellow = !!rect && rect.bottom >= 0 && rect.y < 75;
      setFloat(isBellow);
    };

    document
      .getElementById("canvas-wrapper")
      ?.addEventListener("scroll", handleScroll);
    const refCopy = menuRef.current;

    return () => {
      refCopy?.removeEventListener("scroll", handleScroll);
    };
  }, [menuRef, setFloat]);

  return useMemo(() => {
    return (
      <div className="canvas-wrapper" id="canvas-wrapper">
        <CanvasMenu
          annotationKey={selectedAnnotationKey}
          resetAnnotationKey={() => setSelectedAnnotationKey(null)}
          menuRef={menuRef}
          float={float}
        />
        {float && <div className="canvas-rect-menu-placeholder" />}
        <div className="canvas">
          <img
            className="under-image"
            src={formatImageUrl(pageImageBlob)}
            style={{
              width: adjustedContainerWidth,
              height: adjustedContainerHeight,
            }}
          />
          {Canvas}
        </div>
      </div>
    );
  }, [
    float,
    Canvas,
    pageImageBlob,
    adjustedContainerWidth,
    adjustedContainerHeight,
    selectedAnnotationKey,
  ]);
};
