import React from "react";
import { PlanPage } from "../../model/blueprintServer";
import { formatImageUrl } from "../../util/imageUtil";
import "../planReport/pageView.scss";
import { AppLogoLoader } from "../other/AppLogoLoader";

interface PageViewProps {
  page: PlanPage;
  handleFinishLoadImage: () => void;
  isLoadingImage: boolean;
}

export const LeftHandSide = ({
  page,
  handleFinishLoadImage,
  isLoadingImage,
}: PageViewProps) => {
  return (
    <div className="page-view">
      {isLoadingImage && (
        <div className="loader">
          <AppLogoLoader />
        </div>
      )}
      <img
        src={formatImageUrl(page.pageImageBlob)}
        onLoad={handleFinishLoadImage}
      />
    </div>
  );
};
