import React, { useRef, useState } from "react";
import { FaAngleDown, FaCheck, FaFilter } from "react-icons/fa";
import { useHideOnClickOutside } from "../../other/dropdown/useHideOnClickOutside";
import { Tooltip } from "react-tooltip";
import { useClickOnDropdown } from "../../other/dropdown/useClickOnDropdown";
import { FinalAssessment } from "../../../model/blueprintServer";
import { getReportResultClass, getReportResultText } from "../reportUtil";
import "./finalAssessmentFilter.scss";
import classNames from "classnames";

const TOOLTIP_KEY = "FinalAssessmentFilter";

export type FinalAssessmentSelection = { [key in FinalAssessment]: boolean };

interface FinalAssessmentFilterProps {
  updateCategories: (sel: FinalAssessmentSelection) => void;
  categories: FinalAssessmentSelection;
}

export const FinalAssessmentFilter = ({
  categories,
  updateCategories,
}: FinalAssessmentFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = useClickOnDropdown(ref, setIsOpen);
  useHideOnClickOutside(ref, setIsOpen);

  const handleUpdate = (value: FinalAssessment) => {
    const newSelection = { ...categories, [value]: !categories[value] };
    updateCategories(newSelection);
  };

  const renderItem = (value: FinalAssessment) => (
    <div className="item flex" onClick={() => handleUpdate(value)}>
      <div className={classNames("checkbox", { checked: categories[value] })}>
        <FaCheck />
      </div>
      <div className={classNames("value", getReportResultClass(value))}>
        {getReportResultText(value)}
      </div>
    </div>
  );

  const icon = <FaFilter />;

  return (
    <div
      className="dropdown final-assessment-filter"
      ref={ref}
      data-tooltip-id={TOOLTIP_KEY}
      onClick={handleClick}
    >
      <span className="value-container">
        <div className="value flex">
          {icon}
          <span>Filter results</span>
        </div>
        <FaAngleDown />
      </span>
      <Tooltip id={TOOLTIP_KEY} place="bottom-start" clickable isOpen={isOpen}>
        <div className="dropdown-content">
          <div className="inner">
            <div className="list">
              {renderItem(FinalAssessment.NOT_APPLICABLE)}
              {renderItem(FinalAssessment.DEEPER_REVIEW)}
              {renderItem(FinalAssessment.NO_ENOUGH_DATA)}
              {renderItem(FinalAssessment.COMPLIANCE)}
              {renderItem(FinalAssessment.NON_COMPLIANCE)}
              {renderItem(FinalAssessment.ON_SITE_VERIFICATION)}
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
