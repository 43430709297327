import { useRecoilState, useSetRecoilState } from "recoil";
import { buildingCodeAtom } from "../../../atom/buildingCodeAtom";
import { BuildingCodeRecord, CityName } from "../../../model/blueprintServer";

export const useAddBuildingCode = () => {
  const setCodes = useSetRecoilState(buildingCodeAtom);

  return (
    cityName: CityName,
    codeYear: number,
    buildingCode: BuildingCodeRecord,
  ) =>
    setCodes((old) => {
      if (!old.codes.get(cityName)) {
        old.codes.set(cityName, new Map());
      }
      if (!old.codes.get(cityName)?.get(codeYear)) {
        old.codes.get(cityName)?.set(codeYear, new Map());
      }

      old.codes
        .get(cityName)
        ?.get(codeYear)
        ?.set(buildingCode.fullSectionPath, buildingCode);
      return old;
    });
};
