import { useCallback } from "react";
import { buildingCodeApi } from "../../../api/buildingCodeApi";
import { useRecoilValue } from "recoil";
import { buildingCodeAtom } from "../../../atom/buildingCodeAtom";
import { CityName } from "../../../model/blueprintServer";
import { useAddBuildingCode } from "./useAddBuildingCode";

export const useFetchBuildingCode = () => {
  const { codes } = useRecoilValue(buildingCodeAtom);
  const addCode = useAddBuildingCode();
  const fetchAndCache = useCallback(
    async (cityName: CityName, year: number, sections: string[]) => {
      const newCodes = await buildingCodeApi.getCodes(cityName, year, sections);
      newCodes.forEach((buildingCode) => {
        addCode(cityName, year, buildingCode);
      });
    },
    [addCode],
  );

  return useCallback(
    async (cityName: CityName, year: number, sections: string[]) => {
      const nonExistingCodes = sections.reduce((prev, current) => {
        if (!codes.get(cityName)?.get(year)?.get(current)) {
          return [...prev, current];
        }

        return [...prev];
      }, [] as string[]);

      fetchAndCache(cityName, year, nonExistingCodes);
    },
    [codes, fetchAndCache],
  );
};
