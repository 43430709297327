import { atom } from "recoil";
import { ModalContentComponent } from "../components/theme/useModal";
import { ComponentType, ReactElement } from "react";

export const modalsAtom = atom<ComponentType<
  ModalContentComponent<any>
> | null>({
  key: "modalsAtom",
  default: null,
});
