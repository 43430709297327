import { atom } from "recoil";

interface AppMenuStateAtom {
  collapse: boolean;
}

export const appMenuStateAtom = atom<AppMenuStateAtom>({
  key: "appMenuStateAtom",
  default: {
    collapse: false,
  },
});
