import { PlanQA } from "../../model/blueprintServer";
import React, { useState } from "react";
import { formatImageUrl } from "../../util/imageUtil";

export const PlanQAUI = ({ planQA }: { planQA: PlanQA }) => {
  const [seeJson, setSeeJson] = useState(false);
  const [pageFilter, setPageFilter] = useState<number | null>(null);

  return (
    <div className="plan-qa-container">
      <button className="see-json" onClick={() => setSeeJson((old) => !old)}>
        {seeJson ? "Hide JSON" : "See JSON"}
      </button>
      {seeJson && <pre>{JSON.stringify(planQA, null, "\t")}</pre>}
      <div className="plan-qa-details">
        <div className="plan-qa-item">
          <strong>Plan QA Id:</strong> {planQA.planQaId}
        </div>
        <div className="plan-qa-item">
          <strong>Plan Id:</strong> {planQA.planId}
        </div>
        <div className="plan-qa-item">
          <strong>Title:</strong> {planQA.title}
        </div>
        <div className="plan-qa-item">
          <strong>Created:</strong> {new Date(planQA.created).toDateString()}
        </div>
        <div className="plan-annotations">
          <strong>Annotations:</strong>
          <table className="annotation-table">
            <thead>
              <tr>
                <th>Annotation ID</th>
                <th>Label</th>
                <th>
                  Page Number
                  <input
                    className="page-number-filter"
                    type="number"
                    onChange={(e) =>
                      e.target.value !== ""
                        ? setPageFilter(Number(e.target.value))
                        : setPageFilter(null)
                    }
                  />
                </th>
                <th>Ready</th>
                <th>Image</th>
                <th>Rect</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(planQA.planAnnotation).map((annotationId) => {
                const annotation = planQA.planAnnotation[annotationId];
                if (!!pageFilter && pageFilter !== annotation.pageNumber) {
                  return null;
                }

                return (
                  <tr key={annotation.id}>
                    <td>{annotation.id}</td>
                    <td>{annotation.label}</td>
                    <td>{annotation.pageNumber}</td>
                    <td>{annotation.ready ? "Yes" : "No"}</td>
                    <td>
                      {annotation.image ? (
                        <img
                          src={formatImageUrl(annotation.image)}
                          alt={`Annotation ${annotation.id}`}
                          className="annotation-image"
                        />
                      ) : (
                        "No image"
                      )}
                    </td>
                    <td>
                      <pre>{JSON.stringify(annotation.rect, null, "\t")}</pre>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
