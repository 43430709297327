import React from "react";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { formatImageUrl } from "../../util/imageUtil";
import "./pagesIndex.scss";
import classNames from "classnames";

interface PagesIndexProps {
  setPageIndex: (page: number) => void;
  currentPageIndex: number;
}

export const PagesIndex = ({
  setPageIndex,
  currentPageIndex,
}: PagesIndexProps) => {
  const { activePlan } = useActivePlan();
  if (!activePlan) {
    return null;
  }

  const pages = activePlan.pages.map((page) => (
    <div
      key={page.pageNumber}
      className={classNames("item flex", {
        selected: currentPageIndex === page.pageNumber,
      })}
    >
      <img
        src={formatImageUrl(page.thumbnailBlob)}
        onClick={() => setPageIndex(page.pageNumber)}
        key={page.id}
      />
      <span>{page.pageNumber + 1}</span>
    </div>
  ));
  return <div className="pages-index flex">{pages}</div>;
};
