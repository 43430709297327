import React from "react";
import "./privacyAndTerms.scss";

export const Terms = () => (
  <div className="privacy-and-terms">
    <h2>Terms of Service for Blue Plan AI</h2>
    <b>Last Updated: March 25th, 2024</b>

    <p>
      Welcome to Blue Plan AI! We provide AI-driven mock interviews and practice
      sessions to help you prepare for your job interviews. By accessing or
      using our services, you agree to be bound by these Terms of Service
      ("Terms"). If you do not agree with any part of these Terms, you may not
      use our services.
    </p>
    <p>
      1. Use of Our Services Eligibility: You must be at least [insert minimum
      age, typically 13 or 18] years old to use our services. By agreeing to
      these Terms, you represent and warrant that you meet this age requirement.
      Account Registration: To access certain features of our services, you must
      create an account. You agree to provide accurate, current, and complete
      information during the registration process and to update such information
      to keep it accurate, current, and complete. User Conduct: You agree not to
      misuse our services or help anyone else do so. You are responsible for all
      activities conducted under your account.
    </p>
    <p>
      2. Intellectual Property Our Content: All intellectual property rights in
      or related to our services, including but not limited to software,
      algorithms, content, and branding, are the property of Blue Plan AI or our
      licensors. You may not use any of our intellectual property without our
      prior written consent. User Content: You may generate content through the
      use of our services (e.g., responses to mock interview questions). You
      retain your rights to any content you submit, post, or display on or
      through our services. By using our services, you grant us a worldwide,
      non-exclusive, royalty-free license to use, reproduce, modify, and display
      such content in connection with providing and improving our services.
    </p>
    <p>
      3. Privacy Your privacy is important to us. Our collection and use of
      personal information in connection with our services is described in our
      Privacy Policy.
    </p>
    <p>
      4. Disclaimers and Limitations of Liability No Warranties: Our services
      are provided "as is" and "as available" without any warranties of any
      kind, including implied warranties of merchantability, fitness for a
      particular purpose, or non-infringement. Limitation of Liability: To the
      maximum extent permitted by law, Blue Plan AI shall not be liable for any
      indirect, incidental, special, consequential, or punitive damages, or any
      loss of profits or revenues, whether incurred directly or indirectly, or
      any loss of data, use, goodwill, or other intangible losses.
    </p>
    <p>
      5. Changes to the Terms We reserve the right to modify these Terms at any
      time. If we make material changes, we will notify you by updating the date
      at the top of these Terms and, in some cases, we may provide additional
      notice. Your continued use of our services after such changes have been
      notified will constitute your consent to those changes.
    </p>

    <p>
      6. Termination We may suspend or terminate your access to our services at
      any time, without notice, for any reason, including for breach of these
      Terms.
    </p>
    <p>
      7. Governing Law These Terms will be governed by and construed in
      accordance with the laws of [Your Jurisdiction], without giving effect to
      any principles of conflicts of law.
    </p>
    <p>
      8. Contact Us f you have any questions about these Terms, please contact
      us at hello@offerletterai.com
    </p>
  </div>
);
