import axios, { AxiosInstance } from "axios";

export class ApiInstance {
  axios: AxiosInstance | undefined;

  create(credential?: string) {
    this.axios = axios.create({
      baseURL: "/api",
      withCredentials: false,
      timeout: 60000,
      headers: {
        "fyd-demo-user-id": credential,
        "Content-Type": "application/json",
      },
    });
  }

  get instance() {
    if (!this.axios) {
      throw new Error("Instance is not initiated");
    }

    return this.axios;
  }
}

export const apiInstance = new ApiInstance();
