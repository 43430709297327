import { useActivePlan } from "./useActivePlan";
import { useCallback, useEffect, useState } from "react";
import { usePlanReportsList } from "../../../atom/planReportListAtom";
import { DataLoadingStates } from "../../../model/blueprintServer";
import { planReportApi } from "../../../api/planReportApi";

export const usePollReportsStatus = () => {
  const { activePlan } = useActivePlan();
  const planReportsListData = usePlanReportsList();

  const allReportsLoaded = useCallback(() => {
    if (!activePlan) {
      return true;
    }

    return !planReportsListData
      .data()
      .some((plan) => plan.status === DataLoadingStates.INITIAL_PROCESSING);
  }, [planReportsListData]);

  const maybeUpdateReports = useCallback(() => {
    if (!activePlan) {
      return;
    }

    if (!allReportsLoaded()) {
      planReportApi.getAll(activePlan.id).then((planReports) => {
        planReportsListData.update(planReports);
      });
    }
  }, [allReportsLoaded, activePlan, planReportsListData, activePlan]);

  useEffect(() => {
    if (!activePlan) {
      return;
    }
    const interval = setInterval(() => {
      maybeUpdateReports();
      if (allReportsLoaded()) {
        clearInterval(interval);
      }
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [maybeUpdateReports, allReportsLoaded, activePlan]);
};
