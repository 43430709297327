import { useEffect, useState } from "react";
import { usePlanReportsList } from "../../../atom/planReportListAtom";
import { planReportApi } from "../../../api/planReportApi";
import { useActivePlan } from "./useActivePlan";

export const useLoadPlanReports = () => {
  const planReportsListData = usePlanReportsList();
  const [currentActivePlan, setCurrentActivePlan] = useState("");
  const { activePlan } = useActivePlan();

  useEffect(() => {
    if (!activePlan) {
      return;
    }

    if (
      currentActivePlan === activePlan?.id ||
      planReportsListData.isLoading()
    ) {
      return;
    }

    setCurrentActivePlan(activePlan.id);
    planReportsListData.startLoading();
    planReportApi.getAll(activePlan.id).then((planReports) => {
      planReportsListData.update(planReports);
      planReportsListData.endLoading();
    });
  }, [
    planReportsListData,
    activePlan,
    currentActivePlan,
    setCurrentActivePlan,
  ]);
};
