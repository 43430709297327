import { atom } from "recoil";

interface PageImageSizeAtom {
  containerWidth: number;
  adjustedContainerWidth: number;
  adjustedContainerHeight: number;
  adjustedVideoWidth: number;
  adjustedVideoHeight: number;
  scale: number;
  originalImageWidth: number;
  originalImageHeight: number;
}

export const pageImageSizeAtom = atom<PageImageSizeAtom>({
  key: "pageImageSizeAtom",
  default: {
    containerWidth: 0,
    scale: 1,
    adjustedContainerHeight: 0,
    adjustedContainerWidth: 0,
    adjustedVideoHeight: 0,
    adjustedVideoWidth: 0,
    originalImageWidth: 0,
    originalImageHeight: 0,
  },
});
