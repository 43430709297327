import { useEffect } from "react";
import { useFetchPlanReport } from "../../planReport/hook/useFetchPlanReport";
import { useParams } from "react-router-dom";
import { useActivePlan } from "./useActivePlan";
import { useResetRecoilState } from "recoil";
import {
  currentPlanReportAtom,
  useCurrentPlanReportData,
} from "../../../atom/currentPlanReportAtom";

export const useLoadPlanReport = () => {
  const { fetchPlanReport } = useFetchPlanReport();
  const { reportId } = useParams();
  const { activePlan } = useActivePlan();
  const resetCurrentPlanReport = useResetRecoilState(currentPlanReportAtom);
  const planReportData = useCurrentPlanReportData();

  useEffect(() => {
    if (planReportData.isNotLoaded() && reportId && activePlan) {
      fetchPlanReport(reportId, true);
    }

    if (!reportId) {
      resetCurrentPlanReport();
    }
  }, [
    reportId,
    fetchPlanReport,
    activePlan,
    planReportData,
    resetCurrentPlanReport,
  ]);
};
