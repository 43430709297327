import React, { ReactElement, useCallback, useMemo, useState } from "react";
import "./rightHandSideForReport.scss";
import {
  FinalAssessment,
  SectionComplianceReport,
} from "../../model/blueprintServer";
import { SectionComplianceReportCard } from "../planReport/SectionComplianceReportCard";
import { getReportResultText } from "../planReport/reportUtil";
import { ReportResultFilter } from "../planReport/reportSectionFilter/ReportResultFilter";
import { FinalAssessmentSelection } from "../planReport/reportSectionFilter/FinalAssessmentFilter";
import { RightHandSideFoProps } from "./SideBySideView";

export interface SectionComplianceFilter {
  input: string;
  categories: FinalAssessmentSelection;
}

interface RightHandSideForReportProps
  extends RightHandSideFoProps<SectionComplianceReport> {}

export const RightHandSideForReport = ({
  items,
}: RightHandSideForReportProps) => {
  const [filters, setFilters] = useState<SectionComplianceFilter>({
    input: "",
    categories: {
      [FinalAssessment.COMPLIANCE]: true,
      [FinalAssessment.NON_COMPLIANCE]: true,
      [FinalAssessment.ON_SITE_VERIFICATION]: true,
      [FinalAssessment.NO_ENOUGH_DATA]: true,
      [FinalAssessment.DEEPER_REVIEW]: true,
      [FinalAssessment.NOT_APPLICABLE]: true,
    },
  });

  const filterItem = useCallback(
    (item: SectionComplianceReport) => {
      const { input } = filters;
      const inputLowerCase = input.toLowerCase();
      const inPath = item.buildingCode.fullSectionPath
        .toLowerCase()
        .includes(inputLowerCase);
      const inTitle = item.buildingCode.title
        .toLowerCase()
        .includes(inputLowerCase);
      return inTitle || inPath;
    },
    [filters],
  );

  const groupedItems = useMemo(() => {
    return [...items]
      .sort((a, b) =>
        a.buildingCode.fullSectionPath.localeCompare(
          b.buildingCode.fullSectionPath,
        ),
      )
      .reduce(
        (
          map: Record<FinalAssessment, ReactElement[]>,
          item: SectionComplianceReport,
        ) => {
          const key = item.finalAssessment;
          if (!map[key]) {
            map[key] = [];
          }

          if (filterItem(item)) {
            map[key].push(
              <SectionComplianceReportCard
                item={item}
                key={item.buildingCode.fullSectionPath}
              />,
            );
          }

          return map;
        },
        {} as Record<FinalAssessment, ReactElement[]>,
      );
  }, [items, filterItem]);

  const allSections = useMemo(() => {
    return Object.entries(groupedItems)
      .filter(([key]) => filters.categories[key as FinalAssessment])
      .map(([key, items]) => {
        return (
          <div className="group" key={key}>
            <div className="header flex">
              {getReportResultText(key)}
              <span>({items.length})</span>
            </div>
            <div className="section flex">{items}</div>
          </div>
        );
      });
  }, [groupedItems, filters.categories]);

  return (
    <div className="right-hand-size-for-report flex">
      <ReportResultFilter filters={filters} setFilters={setFilters} />
      <div className="results flex">{allSections}</div>
    </div>
  );
};
