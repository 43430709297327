import { atom } from "recoil";
import { BuildingCodeRecord } from "../model/blueprintServer";

interface BuildingCodeSectionsAtom {
  // City -> year -> code
  codes: Map<string, Map<number, Map<string, BuildingCodeRecord>>>;
}

export const buildingCodeAtom = atom<BuildingCodeSectionsAtom>({
  key: "buildingCode",
  default: {
    codes: new Map(),
  },
});
