import React, { useMemo, useState } from "react";
import { Box } from "../../theme/Box";
import "./active-plan.scss";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "../../../atom/currentProjectAtom";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { UploadContainer } from "../../other/UploadContainer";
import { Dropdown } from "../../other/dropdown/Dropdown";
import { assertDefined } from "../../../util/assertions";
import { useActivePlan } from "../hook/useActivePlan";
import { ProcessingOrReadyChip } from "./ProcessingOrReadyChip";
import { SpinnerLoader } from "../../other/SpinnerLoader";

export const ActivePlan = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const { activePlan, setActivePlan, isProcessingPlan } = useActivePlan();
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  const planUpload = useMemo(
    () => (
      <div className="upload-box">
        <span className="label" />
        <UploadContainer
          setLoadingState={(isLoading) => setIsLoadingUpload(isLoading)}
          text={
            <>
              <AiOutlineCloudUpload />
              <span>
                {currentProject.plans.length
                  ? "Update plan"
                  : "Upload your first plan"}
              </span>
            </>
          }
        />
      </div>
    ),
    [currentProject.plans.length],
  );

  const plansDropdown = useMemo(() => {
    return (
      <div>
        <span className="label">Version</span>
        <Dropdown
          value={activePlan}
          values={[...currentProject.plans]}
          valueFn={(p) => p?.title ?? ""}
          onChange={(plan) => setActivePlan(assertDefined(plan))}
          placeholder={"Upload your first plan"}
          disabled={!currentProject.plans.length || isLoadingUpload}
        />
      </div>
    );
  }, [currentProject.plans, activePlan, setActivePlan, isLoadingUpload]);

  return (
    <>
      <Box
        title={
          <div className="flex loading-title">
            {!!currentProject.plans.length && (
              <ProcessingOrReadyChip isProcessingPlan={isProcessingPlan} />
            )}
            <span>Active plan</span>
          </div>
        }
        body={
          <>
            <div className="top-bar flex">
              {plansDropdown}
              {planUpload}
            </div>
          </>
        }
        actionItem={
          isLoadingUpload ? (
            <SpinnerLoader />
          ) : (
            `Created ${new Date(
              activePlan?.updated ?? new Date(),
            ).toDateString()}`
          )
        }
        classes="active-plan"
      />
    </>
  );
};
