import React, { useRef } from "react";
import { IconType } from "react-icons";
import "./input.scss";
import classNames from "classnames";

export interface InputProps {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
  IconLeft?: IconType;
  ref?: React.MutableRefObject<HTMLInputElement | null>;
}

export const Input = ({
  value,
  onChange,
  IconLeft,
  debounce = 500,
  ...props
}: InputProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className="input flex" onClick={handleOnClick}>
      {IconLeft && (
        <IconLeft className={classNames({ text: !!value && value.length })} />
      )}
      <input
        ref={inputRef}
        {...props}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
