import { planReportApi } from "../../api/planReportApi";
import { useState } from "react";
import {
  CityName,
  FullPlanReport,
  PlanReport,
} from "../../model/blueprintServer";
import { useAddBuildingCode } from "../projects/./hook/useAddBuildingCode";

export const useGenerateReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FullPlanReport>();
  const addCode = useAddBuildingCode();

  const generate = async (
    planId: string,
    cityName: CityName,
    codeYear: string,
    sections: string[],
  ) => {
    setIsLoading(true);
    // const report = await planReportApi.create(
    //   planId,
    //   cityName,
    //   Number(codeYear),
    //   sections,
    // );
    // setData(report);
    // Object.values(report.buildingCodeMap).forEach((buildingCode) =>
    //   addCode(cityName, buildingCode),
    // );
    setIsLoading(false);
  };

  return { isLoading, data, generate };
};
