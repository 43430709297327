import { PlanAnnotation } from "../../model/blueprintServer";

export type Painter = (ctx: CanvasRenderingContext2D) => void;

export interface AnnotationWithPainterData extends PlanAnnotation {
  isHovered: boolean;
  isSelected: boolean;
}

export const runPainter = (ctx: CanvasRenderingContext2D, painter: Painter) => {
  try {
    painter(ctx);
  } catch (e) {
    console.error("Failed to paint");
    console.log(e);
  }
};

export const clearRectPainter =
  (width: number, height: number) => (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, width, height);
  };

export const areaRect =
  (annotations: AnnotationWithPainterData[]) =>
  (ctx: CanvasRenderingContext2D) => {
    let index = 0;
    for (const annotation of annotations) {
      const { start, end } = annotation.rect;
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const minX = Math.min(start.x, end.x);
      const minY = Math.min(start.y, end.y);
      const w = Math.max(start.x, end.x) - minX;
      const h = Math.max(start.y, end.y) - minY;
      ctx.lineWidth = annotation.isHovered || annotation.isSelected ? 6 : 3;
      ctx.strokeStyle = annotation.isSelected ? "red" : "#38f";
      ctx.strokeRect(x, y, w, h);

      ctx.font = "100px serif";
      ctx.fillText(annotation.label, minX, minY - 50);
      index++;
    }
  };
