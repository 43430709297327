import { RefObject, useCallback } from "react";

export const useClickOnDropdown = (
  ref: RefObject<HTMLElement>,
  setIsOpen: (fn: (val: boolean) => boolean) => void,
) => {
  return useCallback(
    (e: any) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen((old) => false); // Close the dropdown if clicked outside
      } else {
        setIsOpen((old) => !old); // Toggle the dropdown if clicked inside
      }
    },
    [setIsOpen, ref],
  );
};
