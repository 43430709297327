import { apiInstance } from "./apiInstance";
import { NewContactRequest } from "../model/contacts";

class ContactsApi {
  async send(request: NewContactRequest): Promise<void> {
    try {
      await apiInstance.instance.post("contacts", request);
    } catch (e) {
      console.error(e);
    }
  }
}

export const contactApi = new ContactsApi();
