import React, { CSSProperties } from "react";
import "./style.scss";
import classNames from "classnames";
import { IconType } from "react-icons";
import { useNavigate } from "react-router-dom";

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}

const sizeClass = (size?: ButtonSize) => {
  switch (size) {
    case ButtonSize.SMALL:
      return "button-small";
    case ButtonSize.MEDIUM:
      return "button-medium";
    case ButtonSize.LARGE:
      return "button-large";
    default:
      return "button-small";
  }
};

export enum ButtonColor {
  BRAND,
  DARK,
  TRANSPARENT,
  ICON,
  DARK_BG,
  BORDER,
}

const colorClass = (color?: ButtonColor) => {
  switch (color) {
    case ButtonColor.DARK:
      return "button-dark";
    case ButtonColor.BRAND:
      return "button-brand";
    case ButtonColor.TRANSPARENT:
      return "button-transparent";
    case ButtonColor.ICON:
      return "button-icon";
    case ButtonColor.DARK_BG:
      return "dark-bg";
    case ButtonColor.BORDER:
      return "button-border";
  }
};

const getIconSize = (buttonSize?: ButtonSize) => {
  switch (buttonSize) {
    case ButtonSize.MEDIUM:
      return 20;
    case ButtonSize.LARGE:
      return 30;
    default:
      return 16;
  }
};

interface ButtonProps {
  onClick?: () => void;
  url?: string;
  children?: React.ReactNode;
  IconLeft?: IconType;
  IconRight?: IconType;
  size?: ButtonSize;
  color?: ButtonColor;
  classes?: string;
  disabled?: boolean;
  actionColor?: boolean;
  hide?: boolean;
  style?: CSSProperties;
}

export const Button = ({
  onClick,
  children,
  IconLeft,
  IconRight,
  size,
  color,
  style,
  url = "",
  classes = "",
  disabled = false,
  actionColor = false,
  hide = false,
}: ButtonProps) => {
  const navigate = useNavigate();
  return (
    <button
      disabled={disabled}
      onClick={onClick ? onClick : () => navigate(url)}
      style={style}
      className={classNames(
        "theme-button",
        sizeClass(size),
        colorClass(color),
        classes,
        { "action-color": actionColor, hide, icon: IconLeft || IconRight },
      )}
    >
      <div className="button-container flex">
        {IconLeft && (
          <div className="icon">
            <IconLeft size={getIconSize(size)} />
          </div>
        )}
        <div>{children}</div>
        {IconRight && (
          <div className="icon">
            <IconRight size={getIconSize(size)} />
          </div>
        )}
      </div>
    </button>
  );
};
